import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Image from "react-bootstrap/Image";
import '../App.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from 'react';

import byjus from "../assets/clients/byjus.png";
import campk12 from "../assets/clients/campk12.png";
import classplus from "../assets/clients/classplus.png";
import crisil from "../assets/clients/crisil.png";
import gomechanic from "../assets/clients/gomechanic.png";
import greatlearning from "../assets/clients/greatlearning.jpg";
import housing from "../assets/clients/housing.png";
import icici from "../assets/clients/icici.png";
import indimart from "../assets/clients/indimart.png";
import ivyhomes from "../assets/clients/ivyhomes.png";
import jigsaw from "../assets/clients/jigsaw.png";
import jodo from "../assets/clients/jodo.png";
import khatabook from "../assets/clients/Khatabook.png";
import leadschool from "../assets/clients/leadschool.png";
import letstransport from "../assets/clients/letstransport.png";
import lido from "../assets/clients/lido.png";
import maxlife from "../assets/clients/maxlife.png";
import motilal from "../assets/clients/motilal.png";
import mygate from "../assets/clients/mygate.png";
import nobroker from "../assets/clients/nobroker.png";
import planetspark from "../assets/clients/planetspark.png";
import proptiger from "../assets/clients/proptiger.png";
import scaler from "../assets/clients/scaler.png";
import scholr from "../assets/clients/scholr.png";
import shyft from "../assets/clients/shyft.png";
import skilllync from "../assets/clients/skilllync.png";
import spinny from "../assets/clients/spinny.png";
import stanzaliving from "../assets/clients/stanzaliving.png";
import sutherland from "../assets/clients/sutherland.png";
import teachmint from "../assets/clients/teachmint.png";
import teleperformance from "../assets/clients/teleperformance.png";
import toprr from "../assets/clients/toprr.png";
import traveltriangle from "../assets/clients/traveltriangle.png";
import treebo from "../assets/clients/treebo.png";
import unacademy from "../assets/clients/Unacademy.png";
import urbancompany from "../assets/clients/urbancompany.png";
import vedantu from "../assets/clients/vedantu.png";
import venwiz from "../assets/clients/venwiz.png";
import whitehat from "../assets/clients/whitehat.png";
import wns from "../assets/clients/wns.jpg";
import upgrad from "../assets/clients/upgrad.png";
import rupeek from "../assets/clients/rupeek.png";
import cultfit from "../assets/clients/cultfit.png";
import pepperfry from "../assets/clients/pepperfry.png";
import adobe from "../assets/clients/adobe_logo.png";
import procol from "../assets/clients/procol_logo.png";
import lambdatest from "../assets/clients/lambdatest_logo.png";
import rewild from "../assets/clients/rewild_logo.png";
import aakash from "../assets/clients/aakash_logo.png";
import idp from "../assets/clients/idp_logo.png";
import clubmahindra from "../assets/clients/club_mahindra_logo.png";
import leverageedu from "../assets/clients/leverage_edu.png";
import mensabrands from "../assets/clients/mensa_brands_logo.png";
import paytm from "../assets/clients/paytm_logo.png";
import policybazaar from "../assets/clients/policy_bazaar_logo.png";
import hexaware from "../assets/clients/hexaware_logo.png";
import relienceretail from "../assets/clients/reliance_retail_logo.png";

let obj = [
  adobe,
  pepperfry,
  relienceretail,
  cultfit,
  upgrad,
  procol,
  aakash,
  idp,
  clubmahindra,
  leverageedu,
  mensabrands,
  paytm,
  policybazaar,
  hexaware,
  // primus,
  rupeek,
  khatabook,
  spinny,
  lambdatest,
  // rewild,
  byjus,
  indimart,
  housing,
  icici,
  crisil,
  // gomechanic,
  motilal,
  traveltriangle,
  urbancompany,
  vedantu,
  treebo,
  unacademy,
  maxlife,
  whitehat,
  wns,
  nobroker,
  // scholr,
  shyft,
  skilllync,
  stanzaliving,
  greatlearning,
  teleperformance,
  letstransport,
  classplus,
  jodo,
  leadschool,
  mygate,
  sutherland,
  // lido,
  // venwiz,
  teachmint,
  // toprr,
  // campk12,
  proptiger,
  scaler,
  // planetspark,
  ivyhomes,
  // jigsaw,
];

function Partner() {
  const [clients, setClients] = useState(obj);

  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
  };
  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
  };
  const settings3 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
  };

    return (
      <div
        style={{
          background: "white",
        }}
        className="py-5 px-3 md:px-5 md:py-5"
      >
        <div
          className="text-center mb-5 text-4xl font-semibold"
          style={{ color: "#836BBD" }}
        >
          Our Hiring Partners
        </div>
        <div className="flex flex-col gap-1 md:hidden">
          <Slider {...settings1}>
            {clients.slice(0, 15).map((item) => {
              return (
                <div>
                  <div>
                    <img src={item} className="h-24 object-contain" style={{width: "60%"}} />
                  </div>
                </div>
              );
            })}
          </Slider>
          <Slider {...settings2}>
            {clients.slice(15, 30).map((item) => {
              return (
                <div>
                  <div>
                    <img src={item} className="h-24 object-contain" style={{width: "60%"}} />
                  </div>
                </div>
              );
            })}
          </Slider>
          <Slider {...settings3}>
            {clients.slice(30).map((item) => {
              return (
                <div>
                  <div>
                    <img src={item} className="h-24 object-contain" style={{width: "60%"}} />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        {/* <div className="hidden md:mx-auto md:grid md:grid-cols-10 md:gap-3 md:w-11/12 md:mx-auto">
          {clients.map((item) => {
            return (
              <div className="flex items-center justify-center shadow-md px-2">
                <img src={item} className="w-32 h-32 object-contain" />
              </div>
            );
          })}
        </div> */}
        <div className="hidden md:w-11/12 md:mx-auto md:grid md:grid-cols-4 md:gap-7">
          {clients.map((item) => {
            return (
              <div className="flex items-center justify-center shadow-md">
                <img src={item} className="h-32 object-contain" style={{width: "60%"}} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  
  export default Partner;
  