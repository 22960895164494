import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "../App.css";
import banner from "../assets/Banner.png";

function FirstMain() {
  return (
    <div
      style={{
        background:
          "linear-gradient(180deg, #836BBD 49.79%, #FFFFFF 99.98%, rgba(131, 107, 189, 0.0750001) 99.99%)",
      }}
      className="px-3 md:flex md:items-center md:justify-evenly pb-5 md:pb-10"
    >
      <div className="mb-3">
        <div className="text-4xl font-semibold" style={{ padding: "25px 0" }}>
          Finding the right job isn’t fate, <br /> it’s navigation.
        </div>
        <div className="flex items-center gap-4 mt-3 md:mt-10">
          <a
            href="https://app.qrate.ai/candidate/form/3"
            target="__blank"
            className="bg-white py-2 px-3 rounded-3xl cursor-pointer shadow-md no-underline"
            style={{ color: "#836BBD" }}
          >
            Get Hired
          </a>
          <a
            href="https://app.qrate.ai/candidate/form/172"
            target="__blank"
            className="bg-white py-2 px-3 rounded-3xl cursor-pointer shadow-md no-underline"
            style={{ color: "#836BBD" }}
          >
            Hire From Us
          </a>
        </div>
      </div>
      <img src={banner} className="w-full md:max-w-md rounded-md" />
    </div>
  );
}

export default FirstMain;
