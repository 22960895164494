import React from "react";
import image from "../assets/Aviate-HR-Program.jpg";
import pdf from "../assets/Aviate-HR-Program.pdf";
import Clients from "./Clients";
import Features from "./Features";
import Instructors from "./Instructors";
import whyToJoin from "../assets/whyToJoin.png";
import CoursePlan from "./coursePlan/CoursePlan";
import Timeline from "./Timeline";
import Testimonials from "./Testimonials";
import whoShouldAttend from "../assets/whoShouldAttend.png";
import TermsConditions from "./TermsConditions";
import CourseFees from "./CourseFees";

import compass from "../assets/Compass.png";
import banner from "../assets/hr-banner-2.png";

const roles = [
  "Working HR professionals looking to upskill",
  "Working professionals looking to switch their career to HR",
  "Freshers looking for HR/TA roles",
  "If you want to be a freelance recruiter",
  "People who want to start a venture in HR",
];

function HrProgram() {
  return (
    <div style={{ fontFamily: "Poppins" }}>
      <div
        style={{
          background:
            "linear-gradient(180deg, #836BBD 49.79%, #FFFFFF 99.98%, rgba(131, 107, 189, 0.0750001) 99.99%)",
        }}
        className="px-3 min-h-screen md:flex md:items-center md:justify-between pb-5 md:pb-10"
      >
        <div className="mb-3 md:pl-5">
          <div className="font-semibold" style={{ padding: "25px 0"}}>
            <div className="text-3xl ">Aviate's </div>
            <div className="text-5xl">HR Program</div>
          </div>
          <div className="text-base mt-2">
            India's first 100% Job Guarantee Program for HR and TA Roles
          </div>
          <div className="flex flex-col md:flex-row gap-3 mt-2 text-base">
            <div
              className="bg-white w-56 py-2 px-3 text-center rounded-3xl cursor-pointer shadow-md"
              style={{ color: "#836BBD" }}
              onClick={() =>
                window
                  .open(
                    "https://app.qrate.ai/candidate/jobform/aviate-hr-training-program-RKBWH42V",
                    "_blank"
                  )
                  .focus()
              }
            >
              Apply Now
            </div>
            <a
              href="https://qrateprodstorage.blob.core.windows.net/prodstorage/extra/Aviate brochure-8.pdf"
              target="__blank"
              download="Aviate-HR-Program.pdf"
              className="bg-transparent w-56 py-2 px-3 rounded-3xl cursor-pointer border border-white text-white flex items-center justify-center no-underline"
              style={{ color: "#836BBD" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                />
              </svg>
              Download Brochure
            </a>
          </div>
        </div>
        <div></div>
        <img
          src={banner}
          //className="w-full md:max-w-md rounded-md"
          className="w-full md:w-1/2 absolute right-0"
        />
      </div>

      {/* <div className="px-3 py-5 text-black md:flex md:justify-evenly">
        <img src={whyToJoin} className="w-1/4 hidden md:inline" />
        <div>
          <div className="text-black text-center font-bold text-2xl mb-5 md:px-14">
            <div className="text-4xl" style={{ color: "#836BBD" }}>
              Who Should Attend This Program?
            </div>
            <div className="flex justify-center">
              <div></div>
            </div>
          </div>
          <div className="md:px-14">
            {roles.map((item) => {
              return (
                <div className="font-medium text-right mb-1 flex items-center justify-end gap-1">
                  <div>{item}</div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-end md:px-14">
            <div
              onClick={() =>
                window
                  .open(
                    "https://dev-app.aviate.jobs/candidate/form/10?job_id=24",
                    "_blank"
                  )
                  .focus()
              }
              className="bg-black text-white w-fit px-3 py-2 rounded-3xl mt-2 cursor-pointer"
            >
              Apply Now
            </div>
          </div>
          <img src={whoShouldAttend} className="w-full md:w-3/5 md:mx-auto" />
        </div>
      </div> */}

      <Features />
      {/* <CoursePlan /> */}
      <Instructors />
      <Testimonials />
      <Timeline />
      <Clients />
      
      {/* <CourseFees /> */}
      <TermsConditions />
    </div>
  );
}

export default HrProgram;
