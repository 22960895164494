import React from "react";
import timeline from "../assets/timeline.png";
import CourseFees from "./CourseFees";

function Timeline() {
  return (
    <div className="flex flex-col px-3 py-5 md:py-10">
      <div className="text-black text-center font-bold text-2xl mb-5 md:px-14">
        <div className="text-4xl" style={{ color: "#836BBD" }}>
          Course Timeline & Details
        </div>
        <div className="flex justify-center">
          <div></div>
        </div>
      </div>
      <img src={timeline} className="w-full md:w-3/5 md:mx-auto" />
      <CourseFees />
    </div>
  );
}

export default Timeline;
