import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
//import Image from "react-bootstrap/Image";
import { Carousel } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "../App.css";
import { candidate } from "./Testimonial-data.js";

function JobSeekerTestimonial() {
  return (
    <div
      style={{
        background: "white",
      }}
      className="py-5 px-3 md:px-5 md:py-5"
    >
      <div
        className="text-center mb-5 text-4xl font-semibold"
        style={{ color: "#836BBD" }}
      >
        Candidate Testimonials
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:w-11/12 md:mx-auto">
        {candidate.slice(0, 6).map((item, index) => {
          return (
            <div className="text-center bg-white p-4 flex gap-3 text-black shadow-md">
              <img src={item.image} className="w-20 h-20 rounded-full" />
              <div className="text-left">
                <div>{item.message}</div>
                <div className="mt-2 flex items-center gap-3">
                  <div>
                    <div style={{ color: "#4A9BF7" }}>{item.name}</div>
                    {/* <div className="text-sm">{item.designation}</div> */}
                  </div>
                  <img src={item.company} className="w-16" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default JobSeekerTestimonial;
