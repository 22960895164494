import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaUsers } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";
import { SiConvertio } from "react-icons/si";

const obj = [
  {
    icon: "",
    background: "",
    title: "Improved Interview conversion ratio",
    text: "In general, over 90% of candidates are rejected in interviews by employers. Conversion ratios for our cohorts are typically 5X of the average and helps save valuable hiring time for recruiters and managers.",
  },
  {
    icon: "",
    background: "",
    title: "Improved attrition rate",
    text: "Our process requires applicants to make an upfront time investment in training and assessments. This ensures a lower ratio of casual applicants and leads to lower attrition rates from our cohorts.",
  },
  {
    icon: "",
    background: "",
    title: "Job-ready candidates",
    text: "Aviators are pre trained and assessed on parameters that are deemed important by employers. This enables an improved orientation about the role, the company and the industry.",
  },
];

const background = [
  "rgba(49, 158, 207, 0.25)",
  "rgba(48, 179, 108, 0.29)",
  "rgba(47, 63, 172, 0.3)",
];

function Employer() {
  return (
    <div
      style={{
        background: "linear-gradient(180deg, #FFFFFF 0%, #836BBD 80%)",
      }}
      className="py-5 px-3 md:px-5 md:py-5"
      id="foremployers"
    >
      <div
        className="text-center mb-5 text-4xl font-semibold"
        style={{ color: "#836BBD" }}
      >
        How Aviate helps Employers
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:w-11/12 md:mx-auto">
        {obj.map((item, index) => {
          let color = background[index];
          return (
            <div className="text-center bg-white rounded-xl p-4">
              <div>
                <div
                  style={{ background: color }}
                  className="w-20 h-20 rounded-full bg-lime-200 mx-auto mb-4 flex items-center justify-center"
                >
                  {index === 0 ? (
                    <SiConvertio size={40} color="#319ECF" />
                  ) : index === 1 ? (
                    <IoStatsChart size={40} color="#30B26C" />
                  ) : (
                    <FaUsers size={40} color="#1F2B98" />
                  )}
                </div>
                <div
                  className="font-semibold text-xl mb-1"
                  style={{ color: "#836BBD" }}
                >
                  {item.title}
                </div>
              </div>
              <div className="text-black">{item.text}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Employer;
