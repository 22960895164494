import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../App.css";
import { FaChalkboardTeacher, FaUsers } from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";

const obj = [
  {
    icon: "",
    background: "rgba(47, 63, 172, 0.17)",
    title: "Training",
    text: "We train candidates on company specific and role specific courses that are designed in collaboration with the recpective employers.",
  },
  {
    icon: "",
    background: "rgba(47, 63, 172, 0.17)",
    title: "Mentorship",
    text: "All trained applicants undergo a mentorship and feedback session designed to help them crack interviews.",
  },
  {
    icon: "",
    background: "rgba(47, 63, 172, 0.17)",
    title: "Quick Turn Around Time",
    text: "Aviate predicts the companies most likely to hire you with a  short 15 minute skills test. Job discovery to job offers happen within a week.",
  },
];

const background = [
  "rgba(47, 63, 172, 0.17)",
  "rgba(185, 87, 120, 0.41)",
  "rgba(115, 72, 31, 0.45)",
];

function JobSeeker() {
  return (
    <div
      style={{
        background: "linear-gradient(180deg, #FFFFFF 0%, #836BBD 80%)",
      }}
      className="py-5 px-3 md:px-5 md:py-5"
    >
      <div
        className="text-center mb-5 text-4xl font-semibold"
        style={{ color: "#836BBD" }}
      >
        How Aviate helps Job - Seekers
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:w-11/12 md:mx-auto">
        {obj.map((item, index) => {
          let color = background[index];
          return (
            <div className="text-center bg-white rounded-xl p-4">
              <div>
                <div
                  style={{ background: color }}
                  className="w-20 h-20 rounded-full mx-auto mb-4 flex items-center justify-center"
                >
                  {index === 0 ? (
                    <FaChalkboardTeacher size={40} color="#3746AA" />
                  ) : index === 1 ? (
                    <FaUsers size={40} color="#B95778" />
                  ) : (
                    <BsClockHistory size={40} color="#73481F" />
                  )}
                </div>
                <div
                  className="font-semibold text-xl mb-1"
                  style={{ color: "#836BBD" }}
                >
                  {item.title}
                </div>
              </div>
              <div className="text-black">{item.text}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default JobSeeker;
