import React from "react";
import features from "../assets/features.png";
import { BsCardChecklist } from "react-icons/bs";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiAzurepipelines } from "react-icons/si";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { BiSolidVideos } from "react-icons/bi";

// const featuresSet = [
//   "100% Job Guarantee",
//   "Learn from the BEST HR/TA leaders",
//   "Learn cutting-edge Automation stratergies",
//   "Live Industry Projects",
//   "Small batches for individual attention",
//   "50+ hours of Content",
// ];

const featuresSet = [
  {text: "100% Job Guarantee", icon: <BsCardChecklist style={{fontSize: "3rem", color: "rgb(131, 107, 189)"}} /> },
  {text: "Learn from the BEST HR/TA leaders", icon: <FaChalkboardTeacher style={{fontSize: "3rem", color: "rgb(131, 107, 189)"}} /> },
  {text: "Learn cutting-edge Automation stratergies", icon: <SiAzurepipelines style={{fontSize: "2.5rem", color: "rgb(131, 107, 189)"}} /> },
  {text: "Live Industry Projects", icon: <AiOutlineFundProjectionScreen style={{fontSize: "3rem", color: "rgb(131, 107, 189)"}} /> },
  {text: "Small batches for individual attention", icon: <HiUserGroup style={{fontSize: "3rem", color: "rgb(131, 107, 189)"}} /> },
  {text: "50+ hours of Content", icon: <BiSolidVideos style={{fontSize: "3rem", color: "rgb(131, 107, 189)"}} /> },
];


function Features() {
  return (
    <div className="flex flex-col px-3 py-5 md:py-10">
      <div className="text-black text-center font-bold text-2xl mb-5 md:px-14">
        <div className="text-4xl" style={{ color: "#836BBD" }}>
          Overview & Key Features
        </div>
        <div className="flex justify-center">
          <div></div>
        </div>
      </div>
      {/* <img src={features} className="w-full md:w-3/5 md:mx-auto" /> */}
      <div 
        style={{color: "#000"}} 
        // className="w-full md:w-3/5 md:mx-auto"
        className="w-full md:w-3/5 md:mx-auto grid grid-cols-1 md:grid-cols-3 gap-2.5 md:gap-5 text-base"
        >
        {featuresSet?.map((feat) => (
          <div style={{
            display: "flex", 
            flexDirection: "column", 
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            // width: "200px", 
            height: "200px", 
            textAlign: "center", 
            border: "1px solid black", 
            borderRadius: "10px",
            fontSize: "1.1rem",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
            }}>
            {feat.icon}
            {feat.text}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Features;
