import "../App.css";
import { singleSuccessStories, successStories } from "./Testimonial-data.js";

function SuccessStoryTestimonial() {
  return (
    <div className="mb-20">
      <div className="text-black text-center font-bold text-2xl mb-5 md:px-14">
        <div className="text-4xl" style={{ color: "#836BBD" }}>
          Candidate Testimonials
        </div>
      </div>
      <div className="w-full md:w-11/12 md:mx-auto grid grid-cols-1 md:grid-cols-1 gap-2.5 md:gap-5 text-base">
        {singleSuccessStories?.map((item) => {
          return (
            <div
              style={{
                background: "white",
                padding: "2vh 1vw",
                borderRadius: "10px",
                boxShadow: "0 0 20px 0 #F0F0F0",
                color: "black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1vw",
                }}
              >
                <img
                  src={item.image}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "100%",
                  }}
                  alt="Profile"
                />
                <div>
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "2.2vh",
                    }}
                  >
                    {item.name}{" "}
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontSize: "2.2vh",
                  color: "grey",
                  marginTop: "1.5vh",
                }}
              >
                {item.message}
              </div>
            </div>
          );
        })}
      </div>

      <div className="w-full md:w-11/12 md:mx-auto grid grid-cols-1 md:grid-cols-2 gap-2.5 md:gap-5 text-base">
        {successStories?.map((item) => {
          return (
            <div
              style={{
                background: "white",
                padding: "2vh 1vw",
                borderRadius: "10px",
                boxShadow: "0 0 20px 0 #F0F0F0",
                color: "black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1vw",
                }}
              >
                <img
                  src={item.image}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "100%",
                  }}
                  alt="Profile"
                />
                <div>
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "2.2vh",
                    }}
                  >
                    {item.name}{" "}
                  </div>
                  {/* <div
                    style={{
                      lineHeight: "1",
                      fontSize: "2.2vh",
                      color: "grey",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5vw",
                    }}
                  >
                    <span>
                      {item.company}
                    </span>
                  </div> */}
                </div>
              </div>

              {/* <div className="flex flex-wrap justify-start mt-1.5">
                {item.tags.map((tag) => (
                  <div className="text-sm px-2 py-0.5 rounded-md bg-purple-700 text-slate-50 my-0.5 mx-1 bg-opacity-70">
                    {tag}
                  </div>
                ))}
              </div> */}

              <div
                style={{
                  fontSize: "2.2vh",
                  color: "grey",
                  marginTop: "1.5vh",
                }}
              >
                {item.message}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SuccessStoryTestimonial;
