import Container from 'react-bootstrap/Container';
import Carousel  from 'react-bootstrap/Carousel';
import './Slider'
function Slider() {
  return(
    <Container>
       <Carousel controls={false} fade={true} pause={false} style={{marginTop:"2%"}}>
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100"
            src='carousel1.png'
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100"
            src="/carousel2.png"
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100"
            src='carousel3.png'
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100"
            src='carousel4.png'
            alt="Fourth slide"
          />
        </Carousel.Item>
      </Carousel>
    </Container>
    );
  }
  
  export default Slider;
  