import akashdeep from "../assets/akashdeep.png";
import avil from "../assets/avil.png";
import arvind from "../assets/arvind-srinivas.png";
import pritam from "../assets/pritam-thakur.png";

import harish from "../assets/harish-vathan.png";
import jhillik from "../assets/jhillik-das.png";
import nikita from "../assets/nikita-khullar.png";
import nishit from "../assets/nishit-gupta.png";
import pragaya from "../assets/prayaga-gopi.png";
import anagha from "../assets/anagha-pirlokar.png";
import ritesh from "../assets/ritesh_dhumal.png";
import mayank from "../assets/mayank.png";

import alok from "../assets/alok.png";
import vinita from "../assets/vinita.png";
import mohith from "../assets/mohith.png";
// import anmol from "../assets/anmol.png";
import nischita from "../assets/nischita.png";
import priyadarshini from "../assets/priyadarshini.png";
import reema from "../assets/reema.png";
import harshit from "../assets/harshit.png";
import rudrakshi from "../assets/rudrakshi.png";
import abhishek from "../assets/abhishek.png";
import karishma from "../assets/karishma.png";
import kartikay from "../assets/kartikay.png";
import mriganko from "../assets/mriganko.png";

import jodo from "../assets/clients/jodo.png";
import unacademy from "../assets/clients/Unacademy.png";
import whitehat from "../assets/clients/whitehat.png";
import campk12 from "../assets/clients/campk12.png";

import byjus from "../assets/clients/byjus.png";
import scholr from "../assets/clients/scholr.png";
import ivy from "../assets/clients/ivy.png";
import upgrad from "../assets/clients/upgrad.png";
import adobe from "../assets/clients/adobe_logo.png";

export const candidate = [
  {
    name: "Ritesh Dhumal",
    designation: "Sales Manager",
    image: ritesh,
    message:
      "I appreciate Aviate’s assistance throughout my hiring process in Adobe. Their follow ups and guidance was spot on which really helped me in getting the offer from Adobe. I Would surely recommend Aviate!",
    company: adobe,
  },
  {
    name: "Anagha Pirlokar",
    designation: "Sales Manager",
    image: anagha,
    message:
      "Best platform I’ve come across so far. The pre interview training program really helps in prepping well for the actual interview rounds. they also provide you deatiled knowledge about not just the job profile but also the industry domain. Very smooth and quick process with a complete handholding till the end.",
    company: whitehat,
  },
  {
    name: "Mayank Mehta",
    designation: "Sales Manager",
    image: mayank,
    message:
      "Hi Team Aviate, you guys really helped me aviate to a new job that too very quickly. It's not just about the speed but you helped my find the right job where I fit well and the salary offered fits well for  me. Getting me 2 different offers in just a week's time is amazing. I loved every bit of interaction I had with your team mate Rishi and Savvy. Wishing you great success. You made my career kickstart all over again. Loads of thanks.",
    company: adobe,
  },
  {
    name: "Prayaga Gopi",
    designation: "Business Development Associate",
    image: pragaya,
    message:
      "Aviate had helped me a lot to land on an amzaing job. They had helped me throughout the process starting from an orientation session, mock interviews till i got my offer letter. They are taking a good effort to ensure us to maximise the chance to get the offer. Thanks to all help from Aviate.",
    company: unacademy,
  },
  {
    name: "Nishit Gupta ",
    designation: "School Sales",
    image: nishit,
    message:
      "Aviate is the best platform for jobseekers. They kind of nail it when it comes to process and matchmaking. They have helped me to get twice with a collective hike of 80% even during pandemic.",
    company: jodo,
  },
  {
    name: "Nikita Khullar",
    designation: "Business Development Associate",
    image: nikita,
    message:
      "I like the way you trained people for their interviews, arranging mock calls, arranging training program which was really very helpful. ",
    company: campk12,
  },
  {
    name: "Jhillik Das",
    designation: "Business Development Associate",
    image: jhillik,
    message:
      "I got selected in Camp K12 with a decent hike on my salary. From beginning they were so co-operative and were justa call away.They prepared me for interview and with all the grilling sessions with the mentors, Interview seemed easy. Thank you Aviate :)",
    company: campk12,
  },
  {
    name: "Harish Vathan",
    designation: "Sales Manager",
    image: harish,
    message:
      "I had a seamless experience throughout the entire process. The trainers really do care about the candidates beyond them being just a lead on the screen.",
    company: whitehat,
  },
];

export const employers = [
  {
    name: "Atulya Bhat",
    designation: "Co-Founder",
    image:
      "https://cdn.sanity.io/images/gxmub2ol/production/2c44830ec4a616ce6ac7b3c64490b0315d213ff4-434x464.jpg",
    message:
      "Aviate has played an instrumental role in building a high performing sales team at Jodo. Their conversion has been order of magnitudes higher than other recruitment partners. If you are looking for responsive & pro-active partners in building your sales team, Aviate is a no-brainer choice!",
    company: jodo,
  },
  {
    name: "Arvind Srinivas",
    designation: "Assistant Manager",
    image: arvind,
    message:
      "The process implementation through tech has reduced the TAT time in the hiring process. The reach of relevant profiles is on the higher side with automated checks on skills criteria with smooth workflows not only from the client's perspective but also ensures a good experience for the candidates as well. Real-time notifications and automated slot bookings are commendable.",
    company: upgrad,
  },
  {
    name: "Pritam Thakur",
    designation: "Co-Founder",
    image: pritam,
    message:
      "The experience with Aviate has been awesome. Aviate has been better than other agencies in Junior and mid-senior related roles. Their database considering number of candidates is larger and also, they have been very receptive to feedback regarding candidates all the time.",
    company: ivy,
  },
  {
    name: "Himanshu Nimje",
    designation: "Co-Founder",
    image:
      "https://pbs.twimg.com/profile_images/1298579757211062272/6ox4Ksdk_400x400.jpg",
    message:
      "I think a very productive platform for hiring sales folks especially. While earlier we used to interview atleast 10-15 candidates to hire 1 person, that has reduced to 2-3 interviews per offer now.",
    company: scholr,
  },
  {
    name: "Avil Cutinha",
    designation: "Talent Acquistion Manager",
    image: avil,
    message:
      "We are glad to have Aviate as our channel partners. Aviate has done a commendable job in the year 2020 with regards to bulk recruitment for Sales roles. We had an excellent conversion ratio last year and are looking forward to more contributions for the year 2021.",
    company: whitehat,
  },
  {
    name: "Akashdeep Singh",
    designation: "Business Development Manager",
    image: akashdeep,
    message:
      "Working with BYJU'S, Aviate has been providing quality profiles which has been their first priority. They have expedited the process of screening which resulted in good conversion ratios and it helped us in reducing our work for screening relevant profiles. BYJU'S highly recommends Aviate.",
    company: byjus,
  },
];

export const singleSuccessStories = [
  {
    name: "Alok Tiwari",
    image: alok,
    message: <>
        <p>I enrolled in the HR upskilling course at Aviate, and it has been a transformative journey over the past three months. The comprehensive curriculum, comprising thought-provoking assignments and insightful masterclasses, provided me with a deep understanding of contemporary HR practices.</p>
        <p>The course structure not only equipped me with theoretical knowledge but also offered practical insights through real-world assignments. The guidance from experienced instructors(special thanks to Rishi Rahul) during the masterclasses was invaluable, offering a bridge between theory and application.</p>
        <p>One highlight of the program was the dedicated placement assistance. The support extended by the career services team was instrumental in helping me navigate the job market with confidence. Their personalized guidance and industry connections proved crucial in securing 3 rewarding offers in HR.</p>
      </>,
  }
]

export const successStories = [
  {
    name: "Vinita Pant",
    image: vinita,
    message:
      "I enjoyed every class. It help me to learn lot of new platforms as a non Hr background I wasn't aware about a lot of tools and process for example recruitment funnel and automation. I learnt variety of tools which I'm sure will be helpful for my future job. I also like the masterclasses where we learnt from best player of market.",
    company: "Placed at Reliance Retail (Milkbasket)",
    tags: ["No Prior HR experience", "1.4x growth in CTC"],
  },
  {
    name: "Mohith Satheesh",
    image: mohith,
    message:
      "It has been a very good experience. It has helped me to learn & understand different aspects of HR. The best part was the class schedules were manageable with office routines, which was a concern for me. The contents where very insightful and starting from basics. Assignment discussion was also very helpful.",
    company: "Placed at Reliance Retail (Milkbasket)",
    tags: ["1.5x growth in CTC"],
  },
  {
    name: "Nischita Shetty",
    image: nischita,
    message:
      "My overall experience has been great. I found the content to be very beneficial and insightful. Learned a lot of new concepts, techniques, and trends in the Talent Acquisition domain. I liked the live assignments and masterclasses the most. Masterclasses from HR experts from various industries helped me understand their viewpoints, their experience and knowledge in this field.",
    company: "Placed at Reliance Retail (Milkbasket)",
    tags: ["No Prior HR experience", "1.4x growth in CTC"],
  },
  {
    name: "Priyadarshini Manivel",
    image: priyadarshini,
    message:
      "Aviate's experience was quite insightful in many ways to me. I got to know the impact that a HR can make in their organisation. And I think that's the most important thing Aviate had taught me. The best part was the master classes. Since we had different people from different domains in the masterclass. There were a lot of take away from the master classes personally to me.",
    company: "Placed at Telstra",
    tags: ["1.4x growth in CTC"],
  },
  {
    name: "Kartikay Singh Rajora",
    image: kartikay,
    message:
      "Overall the course has provided a solid perspective in HR as a domain. For a person like me who is new to HR the course has done it's best in giving me a perspective in the foundations of HR, this is where the content has stood out. The master classes have stood out in letting me know about the overall different functions within HR and their real time applications in organisations.",
    company: "Offers from Cult.fit and Reliance Retail (Milkbasket)",
    tags: ["No Prior HR experience", "1.3x growth in CTC"],
  },
  // {
  //   name: "Harshit Ritolia",
  //   image: harshit,
  //   message:
  //     "It's been a good journey so far with Aviate and the program. The content was really upto the mark, I mean it contains almost everything what an HR/TA domain asks us for. It has also helped us to prepare for the interviews. It certainly did helped us learning as it was totally a new domain for me and it never felt like i won't be able to do it.",
  //   company: "Placed at Cult.Fit",
  //   tags: ["No Prior HR experience"],
  // },

  // {
  //   name: "Abhishek Das",
  //   image: abhishek,
  //   message: "The best part was that the concepts were taught in a simple manner with examples and also followed through masterclass by the industry professionals I think that helped to understand things better and also the recordings are also playing a major role in this as I can acess the content anytime and anywhere that helps a lot.",
  //   company: "",
  //   tags: ["No Prior HR experience"],
  // },
  {
    name: "Reema Nandanwar",
    image: reema,
    message:
      "My overall experience with aviate was very positive, as they helped me in obtaining my first job. The teaching content was designed in such a way that it was simple to understand and implement in real life. The best part about the program was the assignments and masterclasses which was very helpful.",
    company: "Placed at Cult.Fit",
    tags: ["No Prior HR experience"],
  },
  {
    name: "Karishma Shakya",
    image: karishma,
    message:
      "Overall experience has been good. I thought it would be hectic attending classes after office but it went well . So I would say that it was well structured. I found using automation tools very useful and I enjoyed learning excel (pivot tables especially) and data analytics. I also liked the idea of having masterclasses because those people motivated us a lot",
    company: "",
    tags: [],
  },
  // {
  //   name: "Rudrakshi Jain",
  //   image: rudrakshi,
  //   message:
  //     "The overall experience is really incredible. The mentors - Rishi sir and shivika helped me a lot whenever needed specially during the interview preparation. The masterclasses in the program have turned out to be too awesome where we can learn from different leaders from the same industry, it helps us in changing our mindset towards work, HR as a department and ofcourse people.",
  //   company: "",
  //   tags: [],
  // },
  {
    name: "Mriganko Dasgupta",
    image: mriganko,
    message:
      "Aviate's HR Program seems to be a promising course that provides good content on the basics of HR and Talent Acquisition. The program offers exposure to well-known startups and companies that come for placements, which can be a great opportunity for participants to jumpstart their careers. Rishi and Shivika, are truly great mentors as they are supportive and helpful in clarifying any doubts that we as participants may have during the course.",
    company: "Placed at Reliance Retail (Milkbasket)",
    tags: ["No Prior HR experience", "1.4x growth in CTC"],
  }
];
