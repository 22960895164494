import React from "react";

let instructors = [
  {
    name: "Rishi Raj Rahul",
    designation: "Founder @Aviate || MDI Gurgaon",
  },
  {
    name: "Shivika Maheshwari",
    designation: "E&Y || XLRI(HRM)",
  },
];

const guests = [
  {
    name: "Mekin Maheshwari",
    prev_designation: "Ex-Chief People Officer @ Flipkart",
  },
  {
    name: "Ashish Kumar Singh",
    curr_designation: "Chief HR Officer @ Meesho    ",
    prev_prev_designation: "Ex-People Resource Leader @ Adobe",
  },
  {
    name: "Amanpreet Kaur",
    curr_designation: " Talent & Culture Leader @ Cred",
  },
  {
    name: "Roma Bindroo",
    curr_designation: "Chief Human Resources Officer @ Zepto",
    prev_prev_designation: "Ex-HR Leader @ Johnson & Johnson",
  },
  {
    name: "Lissie George",
    curr_designation: "Ex-Director HR @ Morgan Stanley",
    prev_designation: "Ex-Manager HR @ Flipkart"
  },
  {
    name: "Neha Asthana",
    curr_designation: "Director Talent Acquisition @ Dentsu",
    prev_designation: "Head of TA @ Housing"
  },
  {
    name: "Anurag Gupta",
    curr_designation: "Global Head of People Analytics @ BrowserStack"
  },
  // {
  //   name: "Jerin Kesavan",
  //   prev_designation: "Ex-Head Talent Acquisition @ Unacademy",
  //   curr_designation: "Ex-Head Human Resource @ Unacademy",
  //   prev_prev_designation: "Global Head HR @ Interview Kickstart",
  // },
  // {
  //   name: "Sagar Raina",
  //   prev_prev_designation: "Ex-Director Talent Acquisition @ MakeMyTrip",
  //   prev_designation: "Ex-Head Talent Acquisition @ Airtel",
  //   curr_designation: "Head Talent Acquisition @ Delhivery",
  // },
  // {
  //   name: "Chetan Yadav",
  //   prev_designation: "Ex-Chief People Officer @ Tally Solutions",
  //   prev_prev_designation: "Ex-Vice President @ Myntra ",
  // },
  {
    name: "Harsimran Ahuja",
    curr_designation: "General Manager(HR) @ Jodo",
  },
  {
    name: "Rohan Kapoor",
    prev_designation: "Ex-Associate Director(HR) @ Razorpay",
    curr_designation: "Talent Acquisition @ Media.net",
  },
  {
    name: "Sumitra Behera",
    curr_designation: "Associate Director(HR) @ Skill Lync",
  },
  {
    name: "Somnath Mukherjee",
    curr_designation: "AVP - Business @ Zerodha",
  },
  {
    name: "Mohana MD",
    curr_designation: "Ex-Head of HR @ Standard Chartered Bank",
    prev_designation: "Director HR @ SAP"
  },
];

function Instructors() {
  return (
    <div
      // style={{
      //   background: "#0a2351",
      // }}
      style={{
        background: "#836BBD",
        color: "white",
      }}
      className="flex flex-col px-3 py-5 md:py-10"
    >
      <div className="mb-5 md:px-14">
        {/* <div className="text-center font-bold mb-5">
          <div className="text-4xl">Program Instructors</div>
          <div className="flex justify-center">
            <div></div>
          </div>
        </div> */}
        {/* <div className="grid grid-cols-1 gap-2 md:flex md:gap-2 md:justify-center text-base">
          {instructors.map((item) => {
            return (
              <div className="w-full md:w-1/4 border border-slate-100 text-center py-3 px-1">
                <img
                  src={require(`../assets/${item.name
                    .split(" ")[0]
                    .toLowerCase()}_brochure.png`)}
                  style={{
                    //width: "100px",
                    borderRadius: "100%",
                  }}
                  className="mx-auto w-32"
                />
                <div style={{ marginTop: "3vh", fontWeight: "600" }}>
                  {item.name}
                </div>
                <div>{item.designation}</div>
              </div>
            );
          })}
        </div> */}
      </div>
      <div className="md:mx-14">
        <div className="text-center font-bold mb-5">
          <div className="text-4xl">
            <span style={{ color: "#ffd601" }}>Masterclass</span> Series
          </div>
          <div className="text-2xl">
            Learn from HR Leaders
          </div>
          <div className="flex justify-center">
            <div></div>
          </div>
        </div>
        {/* <div className="grid grid-cols-1 gap-2 md:flex md:gap-2 md:items-center md:justify-center text-base"> */}
        <div className="flex flex-col items-center md:container md:mx-auto text-base">
          {guests.map((item, key) => {
            return (
              <div
                className={`w-full flex flex-col item-center border border-slate-100 text-center py-4 px-6 max-w-3xl my-3 ${
                  key % 2 ? "md:flex-row" : "md:flex-row-reverse"
                }`}
              >
                <img
                  src={require(`../assets/${item.name
                    .split(" ")[0]
                    .toLowerCase()}_brochure.png`)}
                  style={{
                    //width: "100px",
                    borderRadius: "10px",
                  }}
                  className="mx-auto md:w-40  w-32"
                />
                <div className="grow-1 md:w-1/2">
                  <div
                    style={{ marginTop: "3vh", fontWeight: "600" }}
                    className="text-xl font-medium text-center"
                  >
                    {item.name}
                  </div>
                  <div className="flex flex-col items-center">
                    {item.curr_designation && (
                      <div className="flex items-center justify-center space-x-4 pt-2">
                        <span>{item.curr_designation}</span>
                      </div>
                    )}
                    {item.prev_designation && (
                      <div className="flex items-center justify-center space-x-4 pt-2">
                        <span>{item.prev_designation}</span>
                      </div>
                    )}
                    {item.prev_prev_designation && (
                      <div className="flex items-center justify-center space-x-4 pt-2">
                        <span>{item.prev_prev_designation}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Instructors;
