import React from "react";
import { FaHandPointRight } from "react-icons/fa";

const obj = [
  "Fees - Rs 39,999 + Taxes(18% GST).",
  // "3-months / 6-months No Interest EMI options available.",
  "Aviate guarantees you a job at a minimum CTC of 3 LPA. If we cannot get you at-least 1 job within 90 days of your graduation, Your entire fee will be refunded.",
];

function CourseFees() {
  return (
    <div className="flex flex-col px-3 pt-5 text-black">
      {/* <div className="text-black text-center font-bold text-2xl mb-5 md:px-14">
        <div className="text-2xl">Course Fees</div>
        <div className="flex justify-center">
          <div></div>
          <div className="h-2 w-16 bg-blue-500"></div>
        </div>
      </div> */}
      <div className="w-full md:w-2/3 mx-auto font-medium text-base">
        {obj.map((item, index) => {
          return (
            <div className="mb-3 flex gap-3">
              <FaHandPointRight size={25} className="shrink-0 text-blue-500" />{" "}
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CourseFees;
