import React from "react";
import SuccessStoryTestimonial from "./SuccessStoryTestimonials.js";
import { employers, candidate } from "./Testimonial-data.js";

function Testimonials() {
  return (
    <div className="flex flex-col px-3 py-5 md:py-10">
      <SuccessStoryTestimonial />
    </div>
  );
}

export default Testimonials;
