import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-scroll";
import { useEffect, useState } from "react";
import logo from "../assets/aviate.png";

function Header() {
  const [headerColor, setHeaderColor] = useState("");

  // useEffect(() => {
  //   if (window.location.pathname.match("/HR-Program")) {
  //     setHeaderColor("#0a2351");
  //   }
  // }, []);

  return (
    <Navbar
      expand="lg"
      variant="dark"
      style={{
        fontFamily: "Poppins",
        background: "#836BBD",
      }}
    >
      <Navbar.Brand href="/">
        <img
          style={{ paddingLeft: "15px" }}
          className="w-24 md:w-32"
          src={logo}
          alt="aviate"
        ></img>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse
        className="justify-content-end"
        style={{ paddingRight: "6%" }}
      >
        <Nav style={{ fontSize: "2.5vh" }}>
          {/* <Nav.Link
            style={{ color: "white", fontWeight: "500", paddingLeft: "55px" }}
            href="/"
          >
            Home
          </Nav.Link> */}
          <Nav.Link
            style={{
              color: "white",
              fontWeight: "500",
              paddingLeft: "55px",
              position: "relative",
            }}
            href="/HR-Program"
          >
            <div
              style={{
                position: "absolute",
                top: "-7px",
                right: "0",
                fontSize: "0.6rem",
                background: "red",
                padding: "0.1vh 0.5vw",
                borderRadius: "5px",
              }}
            >
              New
            </div>
            HR Program
          </Nav.Link>
          <Nav.Link
            style={{ color: "white", fontWeight: "500", paddingLeft: "55px" }}
          >
            <Link
              // to="foremployers"
              // id={id}
              onClick={() => {
                window.location.href = "/#foremployers";
                // setShow(!show);
              }}
              spy={true}
            >
              For employers
            </Link>
          </Nav.Link>
          <Nav.Link
            style={{ color: "white", fontWeight: "500", paddingLeft: "55px" }}
          >
            <Link to="footer" spy={true}>
              Contact Us
            </Link>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
