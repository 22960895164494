import React, { useEffect, useState } from "react";

const obj = [
  "By enrolling in the program, you consent to be open to jobs in the HR/TA domain in Mumbai, NCR, Bangalore, Hyderabad, Pune & Chennai.",
  "Aviate guarantees you a job by your graduation. If we cannot get you at least 1 job within 60 days of your graduation, your entire course fee will be refunded.",
  "Enrollment is considered as consenting to our placement policies.",
  "To be eligible for placement, one must maintain a minimum of 80% attendance and submit all assessments on time. If one doesn’t pass an assessment they will be allowed to reattempt untill they pass.",
  "Detailed terms and conditions will be shared once a candidate clears the admission process and is accepted in the program.",
];

function TermsConditions() {
  const [headerColor, setHeaderColor] = useState("");

  useEffect(() => {
    if (window.location.pathname.match("/terms-conditions")) {
      setHeaderColor(
        "linear-gradient(180deg, #836BBD 49.79%, #FFFFFF 99.98%, rgba(131, 107, 189, 0.0750001) 99.99%)"
      );
    }
  }, []);

  return (
    <div
      className="flex flex-col px-3 py-5 md:py-10 text-black"
      style={{ background: headerColor }}
    >
      <div className="text-black text-center font-bold text-2xl mb-5 md:px-14">
        <div
          className="text-4xl"
          style={headerColor ? { color: "black" } : { color: "#836BBD" }}
        >
          Terms & Conditions
        </div>
        <div className="flex justify-center">
          <div></div>
        </div>
      </div>
      <div className="w-full md:w-2/3 mx-auto font-medium text-base">
        {obj.map((item, index) => {
          return (
            <div className="mb-3">
              {index + 1}. {item}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TermsConditions;
