import React from "react";
import courseplan from "../../assets/course-plan.png";

function CoursePlan() {
  return (
    <div className="py-5 px-3">
      <div className="text-black text-center font-bold text-2xl mb-5 md:px-14">
        <div className="text-4xl" style={{ color: "#836BBD" }}>
          Course Plan
        </div>
        <div className="flex justify-center">
          <div></div>
        </div>
      </div>
      <img src={courseplan} className="w-full md:w-3/5 md:mx-auto" />
    </div>
  );
}

export default CoursePlan;
