import Header from "./components/Header";
import Footer from "./components/Footer";
import FirstMain from "./components/FirstMain";
import Slider from "./components/Slider";
import JobSeeker from "./components/JobSeeker";
import Employer from "./components/Employer";
import EmployerTestimonial from "./components/EmployerTestimonial";
import JobSeekerTestimonial from "./components/JobSeekerTestimonial";
import Partner from "./components/Partner";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import HrProgram from "./components/HrProgram";

function App() {
  return (
    <Router>
      <div style={{fontFamily:'Poppins'}}>
        {/* <Header /> */}
        <Switch>
          <Route path="/HR-program">
            <Header/>
            <HrProgram />
          </Route>
          <Route path="/terms-conditions">
            <TermsConditions />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/">
            <Header bgColor="transparent" />
            <FirstMain />
            {/* <Slider /> */}
            <JobSeeker />
            <JobSeekerTestimonial />
            <Employer />
            <EmployerTestimonial />
            <Partner />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
